.feature-title {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.feature-content {
    color: #50555C;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.actions-group {
    align-items: center;
    flex-flow: row;
}

.small-button {
    background: none;
    border: none;
    margin-right: 16px;
    align-items: center;
    padding: 16px 0;
    color: #50555C;
    outline:none;
    flex-flow: row;
}

.liked {
    color: #7033FF;
}

.disliked {
    color: #FC506F;
}


.small-button p {
    padding: 0 8px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.small-button i {
    font-size: 18px;
}

.feature-cell {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}