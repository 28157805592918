textarea {
    color: #50555C;
    font-size: 16px;
    font-weight: 400;

    background-color: #ffffff;
    width: 100%;
    height: 34px;
    max-height: 240px;
    line-height: 26px;
    border-style: none;
    resize: none;
    outline: none;
    box-sizing: border-box;
    transition: all 200ms linear;
}