.circle-button {
    width: 52px;
    height: 52px;
    border-radius: 32px;

    border: none;
    outline: none;

    background-color: #000000;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-button i {
    font-size: 30px;
}