.features-list {
    list-style-type: none;
    padding-inline-start: 0;
    position: relative;
    top: 44px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.empty-contianer {
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #50555C;
}