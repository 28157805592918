.faq {
    background-color: #ffffff;
    margin-top: 10px;
    padding: 16px;
}

.faq ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.faq ul li {
    border-bottom: solid 1px #e4e4e53b;
}

.faq ul li a {
    text-decoration: none;
}

.faq .material-icons {
    color: #9a9aac;
}

.faq .cell {
    display: block;
    margin: 8px 0;
}