.reply-section {
    border-top: 1px solid #E4E4E5;
    margin-top: 16px;
}

.reply-content {
    color: #50555C;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.By {
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 16px;
}