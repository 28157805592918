body {
  margin: 0;
  background-color: #f8f8f8;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  display: flex;
  flex-flow: column;
}

h1, h2, h3, h4, h5, h6, p {
  margin-block-start: 0;
  margin-block-end: 0;
}

h1 {
  margin-block-start: 16px;
  margin-block-end: 16px;
}

h2 {
  margin-block-start: 14px;
  margin-block-end: 14px;
}

h3 {
  margin-block-start: 12px;
  margin-block-end: 12px;
}

h4 {
  margin-block-start: 10px;
  margin-block-end: 10px;
}

p {
  margin-block-start: 8px;
  margin-block-end: 8px;
}

strong {
  font-weight: 600;
}

button, textarea {
  font-family: "Nunito Sans",sans-serif;
}
