.fixed-button {
    position: fixed;
    bottom: 34px;
    right: 20px;
}

.fixed-button a {
    text-decoration: none;
}

.fluid {
    margin-top: 44px;
}