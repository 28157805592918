.page {
    background-color: #ffffff;
    margin-top: 10px;
}

.page h3 {
    color: #000000;
    font-weight: 700;
}

.page h4 {
    color: #000000;
    font-weight: 600;
}

.page a {
    color: #7033ff;
    font-weight: 600;
}