
.details-view {
    margin: 0 16px;
    padding-bottom: 16px;
}

.details-view h1 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
}

.content-html {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #8B8C90;
}

.content-html h4 {
    color: #000000;
}