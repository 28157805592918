
.nav {
    width: 100%;
    height: 44px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E5;
    position: fixed;
    top: 0;
    z-index: 99;
}

.nav a {
    width: 33.3%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #C4C4C4;
    text-decoration: none;
}

.nav a.active {
    font-weight: 600;
    color: #000000;
    border-bottom: 1px solid #000000;
}