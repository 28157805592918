.container {
    margin-top: 10px;
}

.textarea-wrapper {
    padding: 8px 16px;
    background-color: #ffffff;
    border-bottom: 1px solid #E4E4E5;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #E4E4E5;
}

.title {
    font-weight: 600;
    color: #000000;
}

.text-count {
    font-size: 12px;
    font-weight: 400;
    text-align: right;
    color: #a0a0a0;
    margin-block-start: 4px;
    margin-block-end: 4px;
}

.button-group {
    padding: 16px;
    justify-content: space-around;
    flex-flow: row;
}

.button {
    border-style: none;
    outline: none;
    height: 44px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
}

.Cancel {
    flex: 3;
    margin-right: 10px;
    color: #828282;
    background-color: #F2F2F2;
}

.Submit {
    flex: 7;
    color: white;
    background-color: #7033FF;
}

.GlobalLoader {
    width: 100%;
    background-color: #000000;
    height: 100%;
}