
.list-cell {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}

.list-title {
    color: #50555C;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
}

.expand-panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.list-content {
    color: #8B8C90;
}